import React, { useEffect } from "react";
import { YellowLine } from "../svg";

const carStyles = [
  "Sedan",
  "Hatchback",
  "Coupe",
  "Convertible",
  "SUV",
  "Crossover",
  "Minivan",
  "Pickup Truck",
  "Station Wagon",
  "Roadster",
  "Limousine",
  "Muscle Car",
  "Compact Car",
  "Microcar",
  "Electric Car",
  "Hybrid Car",
  "Luxury Car",
  "Exotic Car",
  "Vintage Car",
  "Concept Car",
  "Van",
  "Tractor"
];

const driveTypes = [
  "Front-wheel drive (FWD)",
  "Rear-wheel drive (RWD)",
  "All-wheel drive (AWD)",
  "Four-wheel drive (4WD)"
];

const fuelTypes = [
  "Gasoline (Petrol)",
  "Diesel",
  "Electric",
  "Hybrid (Gasoline/Electric)",
  "Hydrogen",
  "Ethanol",
  "Biodiesel",
  "Compressed Natural Gas (CNG)",
  "Liquefied Petroleum Gas (LPG)"
];

const transmissionTypes = [
  "Manual transmission",
  "Automatic transmission",
  "Other"
];

export default function StepCarStyle({
  inputs,
  validations,
  setValidations,
  handleInputChange,
  setCurrentStep,
}) {

  useEffect(() => {
    carStyles.map(i => {
      if (inputs?.trim?.toLowerCase()?.includes(i?.toLowerCase())) {
        handleInputChange({ target: { name: 'ucgsubsegment', value: i } })
      }
    })
  }, [inputs?.trim])

  const checkValidations = () => {
    if (!inputs.ucgsubsegment) {
      setValidations((prev) => ({ ...prev, ucgsubsegment: true }));
      return;
    }
    if (!inputs.drivetype) {
      setValidations((prev) => ({ ...prev, drivetype: true }));
      return;
    }
    if (!inputs.fueltype) {
      setValidations((prev) => ({ ...prev, fueltype: true }));
      return;
    }
    if (inputs.transmission === 'N/A' || !inputs.transmission) {
      setValidations((prev) => ({ ...prev, transmission: true }));
      return;
    }
    if (!inputs.mileage) {
      setValidations((prev) => ({ ...prev, mileage: true }));
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <div>
      <h2 className="sub-heading mt-40 mb-0">
        We found your car! <br className="d-lg-block" />
        Let’s confirm a few details.
      </h2>
      <div className="car-functionality mt-30">
        <YellowLine />
        <p className="mb-0 ms-1 text-uppercase d-inline">
          Select your car's <span className="color-purplr">style</span>
        </p>
      </div>
      <form>
        <div className="row mt-30 g-4">
          <div className="col-12 mt-xl-0 mt-3">
            <label className="form-label">Trim</label>
            <input
              type="text"
              className="form-control"
              placeholder="Trim"
              value={inputs.trim}
              readOnly
            />
          </div>
          <div className="col-xl-6 col-12">
            <label className="form-label">Style</label>
            <select
              className="form-select select_form"
              name="ucgsubsegment"
              value={inputs.ucgsubsegment}
              onChange={handleInputChange}
            >
              <option value={""} disabled>
                Select style
              </option>
              <option value={inputs.ucgsubsegment} selected>
                {inputs.ucgsubsegment}
              </option>
            </select>
            {validations.ucgsubsegment && (
              <div className="err_msg">Please select style</div>
            )}
          </div>
          <div className="col-xl-6 col-12">
            <label className="form-label">Drive</label>
            <select
              className="form-select select_form"
              name="drivetype"
              value={inputs.drivetype}
              onChange={handleInputChange}
            >
              <option value={""} disabled>
                Select drive
              </option>
              <option value={inputs.drivetype} selected>
                {inputs.drivetype}
              </option>
            </select>
            {validations.drivetype && (
              <div className="err_msg">Please select drive</div>
            )}
          </div>
          <div className="col-xl-6 col-12">
            <label className="form-label">Fuel Type</label>
            <select
              className="form-select select_form"
              name="fueltype"
              value={inputs.fueltype}
              onChange={handleInputChange}
            >
              <option value={""} disabled>
                Select fuel type
              </option>
              <option value={inputs.fueltype} selected>
                {inputs.fueltype}
              </option>
            </select>
            {validations.fueltype && (
              <div className="err_msg">Please select fuel type</div>
            )}
          </div>
          <div className="col-xl-6 col-12">
            <label className="form-label">Transmission</label>
            <select
              className="form-select select_form"
              name="transmission"
              value={inputs.transmission}
              onChange={handleInputChange}
            >
              <option value={"N/A"} selected disabled>
                Select transmission
              </option>
              {
                inputs.transmission === 'N/A' ?
                  transmissionTypes.map((i, index) => (
                    <option value={i} key={index}>
                      {i}
                    </option>
                  ))
                  :
                  <option value={inputs.transmission} selected>
                    {inputs.transmission}
                  </option>
              }
            </select>
            {validations.transmission && (
              <div className="err_msg">Please select transmission</div>
            )}
          </div>
          <div className="col-xl-6 col-12">
            <label className="form-label">Engine</label>
            <input
              type="text"
              className="form-control"
              placeholder="VIN"
              value={inputs.liters + 'L ' + inputs.engineconfiguration + inputs.cylinders + ' ' + inputs.inductiontype}
              readOnly
            />
          </div>
          <div className="col-xl-6 col-12">
            <label className="form-label">Mileage</label>
            <input
              type="text"
              className="form-control"
              placeholder="Mileage"
              name="mileage"
              value={inputs.mileage}
              onChange={handleInputChange}
            />
            {validations.mileage && (
              <div className="err_msg">Please enter mileage</div>
            )}
          </div>
          <div className="col-md-12">
            <button className="gray-btn border-0 btnyellow" onClick={checkValidations}>
              <span className='wx-170'>Next</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
