import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { MessageBox } from './MessageBox';
import axios from 'axios';
import { ROOT_URL } from '../utils/rootUrl';

export default function SectionWaiting() {

  const [inputs, setInputs] = useState({
    vin_number: "",
    zip_code: "10001",
    state_code: "CA"
  });
  const [validations, setValidations] = useState({
    vin_number: false,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
    if (validations[name])
      setValidations((prev) => ({ ...prev, [name]: false }));
  };

  const checkValidations = () => {
    if (loading) return;
    if (!inputs.vin_number) {
      setValidations((prev) => ({ ...prev, vin_number: true }));
      return;
    }
    getCarDetails();
  };

  const getCarDetails = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${ROOT_URL}/car_vin_detail`, {
        vinNo: inputs.vin_number,
        zip_code: inputs.zip_code,
        state_code: inputs.state_code,
        type: ''
      });
      if (res.data?.status === "success") {
        localStorage.removeItem("offer-details");
        localStorage.removeItem("current-step");
        navigate("/get-offer", {
          state: {
            ...inputs,
            ...(res.data.data?.carVindetails || {}),
            fname: localStorage.getItem("fname") || "",
            lname: localStorage.getItem("lname") || ""
          },
        });
      } else {
        MessageBox("error", res.data?.msg);
      }
    } catch (err) {
      MessageBox("error", err?.response?.data?.msg || "Something went wrong");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container mt-80'>
      <div className="waiting-card">
        <div className="set-trans-bg  perfect-hero-card px-30">
          <div class="text-start"><h2 class="big-middel-heading color-dark mb-1">What are you waiting for?</h2><p class="color-dark font-500 line-20 fs-18 op-70">Enter the registration number to check <br />
            car details instantly</p></div>
          <div className="d-flex align-col-btn gap-2 mt-50">
            <div className="br-input-align">
              <input
                type="text"
                className="form-control input-pt-set"
                placeholder="ENTER VIN NO."
                name="vin_number"
                value={inputs.vin_number}
                onChange={handleInputChange}
              />
            </div>
            <button class="gray-btn set-blue-btn  border-0  btnyellow" onClick={checkValidations}>
              <span class="w-100">
                {loading ? "Loading Offer..." : "get an Instant price"}
              </span>
            </button>
          </div>
          {validations.vin_number && (
            <div className="err_msg">Please enter VIN number</div>
          )}
        </div>
      </div>
      <div className="row align-items-center justify-content-center">
        <div className="col-12">
          <p className='color-dark heding-normal opacity-75 mt-20'>
            * Price excludes tax, title, tags and $399 CarMax processing fee (not required by law). Price assumes that final purchase will be made in the State of KS, unless vehicle is non-transferable. Vehicle subject to prior sale. Applicable transfer fees are due in advance of vehicle delivery and are separate from sales transactions. Limited warranty is 90-Day/4,000-Miles, whichever comes first. See store for written details. We make every effort to provide accurate information, but please verify before purchasing.
          </p>
        </div>
      </div>
      <div className='mt-50'></div>
    </div>
  )
}
