import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { ArrowRight } from "../components/svg";
import StepCarStyle from "../components/get-offer/StepCarStyle";
import StepProgress from "../components/get-offer/StepProgress";
import StepFeatures from "../components/get-offer/StepFeatures";
import StepColorExterior from "../components/get-offer/StepColorExterior";
import StepCarCondition from "../components/get-offer/StepCarCondition";
import StepOffer from "../components/get-offer/StepOffer";
import { useLocation, useNavigate } from "react-router-dom";
import CarValuation from "../components/get-offer/CarValuation";

const defaultInputs = {
  ucgsubsegment: "",
  drivetype: "",
  fueltype: "",
  transmission: "",
  mileage: "",
  features: [],
  ext_color: "",
  int_color: "",
  condition: [],
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  other_info: ""
};

const defaultValidations = {
  ucgsubsegment: false,
  drivetype: false,
  fueltype: false,
  transmission: false,
  mileage: false,
  features: false,
  ext_color: false,
  int_color: false,
  condition: false,
  first_name: false,
  last_name: false,
  email: false,
  phone_number: false,
  other_info: false,
};

export default function GetOffer() {

  const getOfferDetails = localStorage.getItem("offer-details");
  const step = localStorage.getItem("current-step");
  const [inputs, setInputs] = useState(
    JSON.parse(getOfferDetails) || defaultInputs
  );
  const [validations, setValidations] = useState(defaultValidations);
  const [currentStep, setCurrentStep] = useState(
    (Number(step) === 7 ? 6 : Number(step)) || 1
  );
  const [carValuation, setCarValuation] = useState(0)
  const [carId, setCarId] = useState(0)
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state) setInputs((prev) => ({ ...prev, ...state }));
    else navigate("/");
  }, [state]);

  useEffect(() => {
    setInputs((prev) => ({
      ...prev,
      first_name: localStorage.getItem("fname"),
      last_name: localStorage.getItem("lname"),
      email: localStorage.getItem("email"),
      phone_number: localStorage.getItem("phone"),
    }));
  }, []);

  useEffect(() => {
    localStorage.setItem("offer-details", JSON.stringify(inputs));
    localStorage.setItem("current-step", currentStep);
  }, [inputs, currentStep]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "mileage" && !/^[0-9]+$|^$/.test(value)) {
      return;
    }
    if (name === 'phone_number' && !/^[0-9\s().+\-]+$|^$/.test(value)) {
      return
    }
    setInputs((prev) => ({ ...prev, [name]: value }));
    if (validations[name])
      setValidations((prev) => ({ ...prev, [name]: false }));
  };

  const handleBack = () => {
    if (currentStep > 1) setCurrentStep((prev) => prev - 1);
    else navigate("/");
  };

  return (
    <div className="perfect-body bg_gradient pb-5">
      <div className="container">
        <Header className={"light-nav"}/>
        <div className="mt-50">
          <div className="row">
            <div className="col-xl-4 col-lg-5 col-12">
              <StepProgress inputs={inputs} currentStep={currentStep} />
            </div>
            <div className="col-xl-8 col-lg-7 col-12 mt-40 mt-lg-0">
              <div className="yellow-card">
                <div
                  className="d-flex gap-1 align-items-center backbtn pointer"
                  onClick={handleBack}
                >
                  <ArrowRight /> Back
                </div>
                {currentStep === 1 && (
                  <StepCarStyle
                    inputs={inputs}
                    validations={validations}
                    setValidations={setValidations}
                    handleInputChange={handleInputChange}
                    setCurrentStep={setCurrentStep}
                  />
                )}
                {currentStep === 2 && (
                  <StepFeatures
                    inputs={inputs}
                    validations={validations}
                    setValidations={setValidations}
                    handleInputChange={handleInputChange}
                    setCurrentStep={setCurrentStep}
                  />
                )}
                {currentStep === 3 && (
                  <StepColorExterior
                    inputs={inputs}
                    validations={validations}
                    setValidations={setValidations}
                    handleInputChange={handleInputChange}
                    setCurrentStep={setCurrentStep}
                  />
                )}
                {currentStep === 4 && (
                  <StepCarCondition
                    inputs={inputs}
                    validations={validations}
                    setValidations={setValidations}
                    handleInputChange={handleInputChange}
                    setCurrentStep={setCurrentStep}
                  />
                )}
                {currentStep === 5 && (
                  <StepOffer
                    inputs={inputs}
                    validations={validations}
                    setValidations={setValidations}
                    handleInputChange={handleInputChange}
                    setCurrentStep={setCurrentStep}
                    setCarValuation={setCarValuation}
                    setCarId={setCarId}
                  />
                )}
                {currentStep === 6 && (
                  <CarValuation
                    inputs={inputs}
                    setCurrentStep={setCurrentStep}
                    carValuation={carValuation}
                    carId={carId}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
