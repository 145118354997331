import React, { useState } from 'react'
import Header from '../components/Header'
import { YellowLine } from '../components/svg'
import Footer from '../components/Footer'
import SectionWaiting from '../components/SectionWaiting'

const faqs = [
  {
    type: 'Seeing a Car',
    items: [
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it1?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      },
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it2?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      }
    ]
  },
  {
    type: 'Buying a Car',
    items: [
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it3?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      },
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it4?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      },
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it5?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      }
    ]
  },
  {
    type: 'Maintanance & more',
    items: [
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it6?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      },
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it7?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      }
    ]
  }
]

export default function Faq() {

  const [viewTitle, setViewTitle] = useState(null)

  return (
    <div className='perfect-body'>
      <div className='pb-5'>
        <div className='container'>
          <Header />
          <div className="mt-100">
            <div className="text-center">
              <h1 className="mb-3 main-heading color-dark mt-20">
                Frequently Asked Questions
              </h1>
              <p className='color-dark opacity-75'>
                Find answers or connect with a human.
              </p>
            </div>
          </div>
          {
            faqs.map((i, index) =>
              <div key={index}>
                <div className='mt-80 pb-2'>
                  <YellowLine />
                  <p className='mb-0 ms-1 text-uppercase d-inline medium-heading weight-600'>
                    {i.type}
                  </p>
                </div>
                {
                  i.items.map((j, jindex) =>
                    <div className='mt-20 faq-box' key={jindex}>
                      <div className="d-flex gap-4 align-items-center justify-content-between pointer"
                        onClick={() => setViewTitle(viewTitle == j.title ? null : j.title)}>
                        <p className='mb-0 medium-height-heading color-dark'>{j.title}</p>
                        <div className='faq-round px-2 pointer'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                            <path d="M11 1.5L6.70711 5.79289C6.37377 6.12623 6.20711 6.29289 6 6.29289C5.79289 6.29289 5.62623 6.12623 5.29289 5.79289L1 1.5" stroke="#252941" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                      </div>
                      {viewTitle == j.title && <p className='mt-20'>{j.description}</p>}
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
      <SectionWaiting />
      <Footer />
    </div>
  )
}
