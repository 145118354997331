import React, { useState } from "react";
import CarGuide from "./CarGuide";
import { SmallLine, Star } from "./svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";

const faqs = [
  {
    type: "Seeing a Car",
    items: [
      {
        title: `What paperwork is needed to sell my car?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`,
      },
      {
        title: `What are the benefits of selling with CarMax?`,
        description: `Selling your car through CarMax’s platform means you can get an instant True Cash Offer from one of our thousands of Certified Dealers without having to advertise your car, meet up with strangers, or deal with paperwork on your own.`,
      },
      {
        title: `Can I sell my car if I still owe money on it?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`,
      },
      {
        title: `Can I sell my car if I am leasing?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`,
      },
    ],
  },
];
const CardFaq = () => {
  const [viewTitle, setViewTitle] = useState(null);

  return (
    <>
      <div className="mt-60">
        <div className="container">
          {faqs.map((i, index) => (
            <div key={index}>
              {i.items.map((j, jindex) => (
                <div className="mt-20 faq-box" key={jindex}>
                  <div
                    className="d-flex gap-md-4 gap-3 align-items-center justify-content-between pointer"
                    onClick={() =>
                      setViewTitle(viewTitle == j.title ? null : j.title)
                    }
                  >
                    <p className="mb-0 faq_title color-dark">{j.title}</p>
                    <div className="faq-round px-2 pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                      >
                        <path
                          d="M11 1.5L6.70711 5.79289C6.37377 6.12623 6.20711 6.29289 6 6.29289C5.79289 6.29289 5.62623 6.12623 5.29289 5.79289L1 1.5"
                          stroke="#252941"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  {viewTitle == j.title && (
                    <p className="mt-20 faq_pera">{j.description}</p>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const PriceCard = ({ img, name }) => {
  return (
    <>
      <div className="price_space">
        <div className="price-slider-card text-center overflow-visible position-relative">
          <div className="price-slider-main overflow-visible">
            <img src={img} alt="img" className="img-fluid price-man" />
            <div className="card_efext">
              <h5>
                “The offer from Motorway exceeded any other offers I had
                explored by a significant margin.”
              </h5>
              <div className="mt-3">
                <p className="medium-heading color-dark weight-600">
                  <SmallLine /> &nbsp; {name} &nbsp;
                  <SmallLine />{" "}
                </p>
                <p className="mb-0 medium-heading opacity-75">
                  sold her Vauxhall
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default function HomeFaq() {
  return (
    <div>
      <div className="container">
        <section className="padding-block-50 ">
          <div className="text-center">
            <span className="small-bas-heading color-purplr-light">FAQ’s</span>
            <h2 className="big-middel-heading color-dark mt-20">
              Frequently Asked Questions
            </h2>
            <p className="color-dark opacity-75 mt-20">
              This one, just change it to Instant Cash Price, instead of True
              car{" "}
            </p>
          </div>
          <CardFaq />
        </section>
        <section className="padding-block-50 ">
          <div className="d-md-flex justify-content-between">
            <div className="mb-md-0 mb-4">
              <h4 className="middel-heading color-dark-light star_icon">
                <Star />
                Real deals Real <span className="color-purplr-light">Prices</span>
              </h4>
              <p className="opacity-75 mb-0">
                True stories from real PerfectSell customers
              </p>
            </div>
            <button className="gray-btn border-0 btnyellow">
              <span className=" ">Get you Offer</span>
            </button>
          </div>

          <div className="mt-50 position-relative">
            <div className="align-arrow-set">
              <div className="bg-arrow-set deal_prev">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 5.53931L8.20711 9.8322C7.87377 10.1655 7.70711 10.3322 7.70711 10.5393C7.70711 10.7464 7.87377 10.9131 8.20711 11.2464L12.5 15.5393"
                    stroke="#252941"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="bg-arrow-set  deal_next">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 15.5393L11.7929 11.2464C12.1262 10.9131 12.2929 10.7464 12.2929 10.5393C12.2929 10.3322 12.1262 10.1655 11.7929 9.8322L7.5 5.53931"
                    stroke="#252941"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <Swiper
                navigation={{
                    nextEl: ".deal_next",
                    prevEl: ".deal_prev",
                }}
              spaceBetween={15}
              slidesPerView={4}
              modules={[Navigation]}
              className="pt-lg-0 pt-4"
              breakpoints={{
                320: {
                  slidesPerView: 1.6,
                  spaceBetween: 8,
                },

                400: {
                  slidesPerView: 1.8,
                  spaceBetween: 8,
                },

                576: {
                  slidesPerView: 2.2,
                  spaceBetween: 10,
                },

                768: {
                  slidesPerView: 2.8,
                  spaceBetween: 15,
                },

                992: {
                  slidesPerView: 3.5,
                  spaceBetween: 15,
                },

                1200: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                },
              }}
            >
              <SwiperSlide>
                <PriceCard
                  img={"/assets/img/home/priceslider/price1.webp"}
                  name={"Danielle"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <PriceCard
                  img={"/assets/img/home/priceslider/price2.webp"}
                  name={"Nigel"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <PriceCard
                  img={"/assets/img/home/priceslider/price3.webp"}
                  name={"Brian and Sheila"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <PriceCard
                  img={"/assets/img/home/priceslider/price4.webp"}
                  name={"Sheila"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <PriceCard
                  img={"/assets/img/home/priceslider/price5.webp"}
                  name={"Sheila"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <PriceCard
                  img={"/assets/img/home/priceslider/price6.webp"}
                  name={"Sheila"}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
        
      </div>
      <section className="carmax-eye car_guides">
        <div className="container">
          <CarGuide />
        </div>
      </section>
    </div>
  );
}
