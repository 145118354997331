import React, { useEffect, useState } from 'react'
import SectionHero from '../components/SectionHero'
import Processing from '../components/Processing'
import WhyPerfectSell from '../components/WhyPerfectSell'
import SectionHeroTrack from '../components/SectionHeroTrack'
import SectionWaiting from '../components/SectionWaiting'
import Footer from '../components/Footer'
import { Messageicone, Offer1, Offer2, Offer3, Offer4 } from '../components/svg'
import Animation from '../components/Animation'

const faqs = [
  {
    type: 'Seeing a Car',
    items: [
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it1?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      },
      {
        title: `Do I have to buy a car if I reserve it, have it shipped from another store, or test drive it2?`,
        description: `You can reserve 1 car at a time up to 7 days in advance of an appointment and/or test drive, unless otherwise noted. To do this, go to the car's detail page and select one of the reservation options available for that car. If you ship a car, it will be reserved for you while it's in transit.`
      }
    ]
  }
]
export default function ValueTracker() {
  const [viewTitle, setViewTitle] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SectionHeroTrack />
      <section className='padding-block-80'>
        <div className='container'>
          <div className='text-center'>
            <span className='small-bas-heading color-purplr'>Way to sell</span>
            <h2 className='big-middel-heading color-dark mt-3'>How It Works</h2>
            <p className='color-dark opacity-75'>Sell your car the right way. No nonsense. Totally free!</p>
          </div>
          <div className='row mt-60 gap_30 justify-content-lg-start justify-content-center'>
            <div className='col-lg-4 col-md-6'>
              <div className='proccess-card'>
                <div className='proccess-image'>
                  <img src='/assets/img/track/work/time.webp' alt='step 1' />
                  <div className='card-box'>
                    <p className='text-uppercase'>Step 1</p>
                  </div>
                  <img src='/assets/img/track/work/time.png' alt='cars' className='car-animation time-track' />
                  <img src='/assets/img/home/true.svg' alt='cars' className='true time-sale-track' />

                </div>
                <div className='card-content'>
                  <h3 className='sub-heading fw-500'>Get an Estimate Fast</h3>
                  <p className='color-dark m-0 opacity-75'>
                    Enter your car’s information and instantly get a value estimate.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='proccess-card'>
                <div className='proccess-image'>
                  <img src='/assets/img/track/work/show.webp' alt='step 1' />
                  <div className='card-box'>
                    <p className='text-uppercase'>Step 2</p>
                  </div>
                  <img src='/assets/img/home/car4.webp' alt='cars' className='car-animation car-3' />
                  <img src='/assets/img/home/car3.webp' alt='cars' className='car-animation car-4' />
                  <img src="/assets/img/home/card/card1.webp" alt='cars' className='true valutrack-fine' />
                </div>
                <div className='card-content'>
                  <h3 className='sub-heading fw-500'>Fine-Tune Your Value</h3>
                  <p className='color-dark m-0 opacity-75'>
                    See immediately how features like color and mileage affect your car’s value.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='proccess-card'>
                <div className='proccess-image'>
                  <img src='/assets/img/track/work/work.webp' alt='step 1' />
                  <div className='card-box'>
                    <p className='text-uppercase'>Step 3</p>
                  </div>
                  <img src='/assets/img/track/work/wallet.png' alt='cars' className='car-animation sale wallet-tracker' />
                  <img src='/assets/img/track/work/not1.png' alt='cars' className='true sale1 not1-tracker' />
                  <img src='/assets/img/track/work/not2.png' alt='cars' className='true sale2 not2-tracker' />
                </div>
                <div className='card-content'>
                  <h3 className='sub-heading fw-500'>Get Your True Cash Offer™</h3>
                  <p className='color-dark m-0 opacity-75'>
                    Ready to sell your car? Get an offer from a local dealer today.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='padding-block-80 sell_bg'>
        <div className='container'>
          <div className='text-center'>
            <span className='small-bas-heading color-purplr'>Why Perfectsell?</span>
            <h2 className='big-middel-heading color-dark mt-20'>Benefits of Getting a True Cash Offer</h2>
            <p className='color-dark mt-4'>
              Experience fast, flexible, and secure international car selling worldwide.
            </p>
          </div>
          <div className="row mt-100 g-lg-5">
            <div className="col-lg-6 mt-0">
              <div className="d-flex flex-column">
                <div className='d-flex align-items-lg-center align-items-lg-start gap-3'>
                  <div className='offer_icon'>
                    <Offer1 />
                  </div>
                  <div>
                    <h6 className='mb-2 sub-heading'>Quick and Easy Process</h6>
                    <p className='mb-0 opacity-75'>
                      We only ask questions that impact your car's value,
                      so you can get to a real cash offer in minutes.</p>
                  </div>
                </div>
                <div className='d-flex align-items-lg-center align-items-lg-start gap-3 mt-30'>
                  <div className='offer_icon'>
                    <Offer4 />
                  </div>
                  <div>
                    <h6 className='mb-2 sub-heading'>Total Transparency</h6>
                    <p className='mb-0 opacity-75'>
                      Quickly see how factors like mileage, color, and options affect your car's value. Have more questions? A Certified Dealer will explain in person everything that goes into your car's valuation.
                    </p>
                  </div>
                </div>
                <div className='d-flex align-items-lg-center align-items-lg-start gap-3 mt-30'>
                  <div className='offer_icon'>
                    <Offer3 />
                  </div>
                  <div>
                    <h6 className='mb-2 sub-heading'>Get Paid</h6>
                    <p className='mb-0 opacity-75'>
                      A True Cash Offer means you get a check directly from a Certified Dealer. Private party sales, on the other hand, could cost you additional time and money.
                    </p>
                  </div>
                </div>
                <div className='d-flex align-items-lg-center align-items-lg-start gap-3 mt-30'>
                  <div className='offer_icon'>
                    <Offer2 />
                  </div>
                  <div>
                    <h6 className='mb-2 sub-heading'>Locked-In Price</h6>
                    <p className='mb-0 opacity-75'>
                      Not ready to sell today? No worries. Your True Cash Offer is good for three days at a Certified Dealer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-lg-0 mt-5">
              <img src="./assets/img/track/offer.webp" alt="img" className='img-fluid' />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <Animation />
        </div>
      </section>
      <section>
        <div className="container">
          <h2 className='big-middel-heading color-dark mt-20'>
            Selling Your Car
          </h2>
          <p className='mt-20 opacity-75'>
            Time to part ways with your car? Here are a few things you’ll want to know as you’re getting ready to sell.
          </p>
          <div className="mt-60">
            <h6 className='small-heading'>
              Time Commitment
            </h6>
            <p className='opacity-75'>
              First, think about how much time you want to dedicate to selling your car. CarMax lets you get a True Cash Offer for your car in minutes. Answer a few questions to get an offer on the spot. From there, you can take your offer to a Certified Dealer to cash out. Just looking for an estimate? Entering your car’s make and model will generate a price range, so you know what to expect when you decide to sell.
            </p>
          </div>
          <div className="mt-30">
            <h6 className='small-heading'>
              Determining Value
            </h6>
            <p className='opacity-75 mt-3'>
              The condition of your vehicle, plus specific information about trim and modifications, will help you determine its value.
            </p>
            <p className='opacity-75'>
              First, let’s assess your car’s condition.
            </p>
            <ul>
              <li className='opacity-75 mb-2'>Does it have dents and scrapes?</li>
              <li className='opacity-75 mb-2'>Is the interior worn?</li>
              <li className='opacity-75 mb-2'>Are the tires and brakes in good condition?</li>
              <li className='opacity-75 mb-2'>Has it been in an accident?</li>
              <li className='opacity-75'>Is there any rust?</li>
            </ul>
            <p className='opacity-75'>
              This list is not exhaustive, but it should give you an idea of the types of items you’ll want to look at to assess the condition properly. Next, let’s get into some of your car’s specifics.
            </p>
            <ul>
              <li className='opacity-75 mb-2'>What is the trim?</li>
              <li className='opacity-75 mb-2'>Does it have a sunroof or touch screen infotainment system?</li>
              <li className='opacity-75 mb-2'>Did you make after-factory modifications?</li>
            </ul>
          </div>
          <div className="mt-30">
            <h6 className='small-heading'>
              Improving Value
            </h6>
            <p className='opacity-75 mt-3'>
              Wondering how you can get more money for your vehicle? Try improving your car’s appearance. Not only is the car’s exterior appearance the first thing potential buyers will see, it also sets the tone for how well you’ve taken care of your vehicle overall, implying that the vehicle’s maintenance will also be up to date. On the inside, make sure you treat the leather/interior to help it look new, and fix scratches and cracks.
            </p>
            <p className='opacity-75'>
              While regular service checks may not boost the value of your vehicle, they will help prevent your car from accelerated depreciation. You can buy a service plan through your dealer when you purchase a vehicle, and in some cases, you can spread out these costs over the life of your loan. If this helps motivate you to get your car in for service, it can be a good investment.
            </p>
          </div>
          <div className="mt-30">
            <h6 className='small-heading'>
              Dealership versus Private Sale
            </h6>
            <p className='opacity-75 mt-3'>
              There are several factors to consider when deciding whether to sell your car to a dealer or a private party:
            </p>
            <p className='opacity-75'>
              <b>Ownership</b> - Do you own your car outright? If not, what do you owe? Are you leasing? If you owe, you’ll likely need to pay off the balance before selling it to a private party. If you sell it to a dealership, you can let them handle the paperwork instead.
            </p>
            <p className='opacity-75'>
              <b>Time</b> - Selling to a private party will invariably take more time. You’ll have to spruce up your ride, take some pictures, find places to post ads, and then go through the process of meeting buyers. Selling your car to a dealership typically means much less hassle.
            </p>
            <p className='opacity-75'>
              <b>Price</b> - If your car has good trade-in value (a model that’s in demand, has low mileage, or is in good condition) and you have the time and patience to handle buyers, selling to a private party might be the way to go. If you have a car of lesser value and don’t want the hassle, you may want to simply sell it to a dealer and be done with it.
            </p>
          </div>
          <div className="mt-30">
            <h6 className='small-heading'>
              Gather the Paperwork
            </h6>
            <p className='opacity-75 mt-3'>
              Again, if you’re selling to a private party, make sure you have the right paperwork. Here are some items you may need:
            </p>
            <ul>
              <li className='opacity-75 mb-2'> Vehicle Registration </li>
              <li className='opacity-75 mb-2'> Warranty information </li>
              <li className='opacity-75'> Bill of sale </li>
            </ul>
            <p className='opacity-75'>
              To understand which forms you’ll need to complete a car sale in your state, check with your nearest DMV, or contact AAA if you’re a member. Also, it’s a good idea to have your maintenance records on hand, in case you need to refer to them.
            </p>
          </div>
        </div>
      </section>

      <section className='padding-block-80'>
        <div className="text-center">
          <span className='small-bas-heading color-purplr'>FAQ’s</span>
          <h6 className="mb-3 big-middel-heading color-dark mt-20">
            Car Selling FAQ’s
          </h6>
          <p className='color-dark opacity-75'>
            This one, just change it to Instant Cash Price, instead of True car
          </p>
        </div>
        <div className='mt-60'>
          <div className="container">
            {
              faqs.map((i, index) =>
                <div key={index}>
                  {
                    i.items.map((j, jindex) =>
                      <div className='mt-20 faq-box' key={jindex}>
                        <div className="d-flex gap-4 align-items-center justify-content-between pointer" onClick={() => setViewTitle(viewTitle == j.title ? null : j.title)}>
                          <p className='mb-0 medium-height-heading color-dark'>{j.title}</p>
                          <div className='faq-round px-2 pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                              <path d="M11 1.5L6.70711 5.79289C6.37377 6.12623 6.20711 6.29289 6 6.29289C5.79289 6.29289 5.62623 6.12623 5.29289 5.79289L1 1.5" stroke="#252941" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </div>
                        </div>
                        {viewTitle == j.title && <p className='mt-20'>{j.description}</p>}
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
        </div>
      </section>
      <SectionWaiting />
      <Footer />
    </>
  )
}
