import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Car, InstantIcon } from "./svg";
import { Autoplay, Navigation } from "swiper/modules";
import HomeFaq from "./HomeFaq";
import TopDeals from "./TopDeals";
import Processing from "./Processing";

const CarMax = () => {
  return (
    <>
      <div className="waiting-card-for yellow-card w-100  text-start p-3">
        <div className="row">
          <div className="col-6 text-end">
            <img
              src="/assets/img/home/maxway/maxway.webp"
              alt="img"
              className="img-fluid h-100"
            />
          </div>
          <div className="col-6 d-flex flex-column justify-content-center">
            <h6 className="weight-600 medium-heading mb-0">Sold By Shirley</h6>
            <p className="medium-heading opacity-75 color-dark text-uppercase mb-0 mt-2">
              Toyota RAV4, sold for £26,184.
            </p>
            <span className="small-heading-font d-block mt-2">1 Day ago</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default function WhyPerfectSell() {
  const data = [
    {
      src: "/assets/img/home/price.svg",
      title: "Transparent Pricing",
      details:
        "No surprises. Discover how much youll earn from the cars youre looking to sell.",
    },
    {
      src: "/assets/img/home/minute.svg",
      title: "Sell in just few Minutes",
      details:
        "Efficient tools to save you time and find the perfect selling price for your car.",
    },
    {
      src: "/assets/img/home/buy.svg",
      title: "Contact Direct to Buyer",
      details:
        "Secure offline car selling, free from fraud, and no commission involved.",
    },
  ];
  return (
    <>
      <section className="padding-block-80 sell_bg pb-md-5 pb-4">
        <div className="container">
          <div className="text-center">
            {/* <h2 className="big-middel-heading color-dark mt-20">
              Getting an Instant Cash Price.
            </h2> */}
            <h2 className='big-middel-heading fs-80 color-purplr'>Ready. Set. <span className='set-fix-sold'>sold</span></h2>
            <p className="color-dark">
              Sell your car the right way. No nonsense. Totally free!
            </p>
          </div>
          <div className="row order-set-align gap_30 mt-90 align-items-center justify-content-lg-start justify-content-center">
            <div className="set-alignment-order">
            <div className="col-lg-6 text-center ">
              <img src="/assets/img/icons/cardf.svg" alt="" />
            </div>
            <div className="col-lg-6 ">
              <div className="content-right-set">
                <h3 className="sub-heading fw-500">Quick and Simple Process</h3>
                <p className="color-dark m-0 weight-500 opacity-75">
                  We ask few questions relating to your vehicles current
                  condition, so you can get a real price.
                </p>
              </div>
            </div>
            </div>
            <div className="set-alignment-order flex-col-re">
            <div className="col-lg-6 ">
              <div className="">
                <h3 className="sub-heading fw-500">Instant Clarity</h3>
                <p className="color-dark m-0 weight-500 opacity-75">
                  Factors like mileage, color, and options affect your car's
                  price. Have more questions? A Certified Dealer will explain in
                  person everything that goes into your car's valuation.
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <img src="/assets/img/icons/cards.svg" alt="" />
            </div>
            </div>
            <div className="set-alignment-order">
            <div className="col-lg-6 text-center">
              <img src="/assets/img/icons/cardt.svg" alt="" />
            </div>
            <div className="col-lg-6 ">
              <div className="content-right-set">
                <h3 className="sub-heading fw-500">Get Paid</h3>
                <p className="color-dark m-0 weight-500 opacity-75">
                  Take your car with your Instant Cash Price to a Certified
                  Dealer and get Paid! Save the scams and headaches of private
                  sales.
                </p>
              </div>
            </div>
            </div>
            <div className="set-alignment-order flex-col-re">
            <div className="col-lg-6 ">
              <div className="">
                <h3 className="sub-heading fw-500">Not ready to sell?</h3>
                <p className="color-dark m-0 weight-500 opacity-75">
                  Factors like mileage, color, and options affect your car's Not
                  ready to sell? You got it. Your Instant Cash Price is good for
                  seven days at a Certified Dealer.
                </p>
              </div>
            </div>
            <div className="col-lg-6  text-center">
              <img src="/assets/img/icons/cardfo.svg" alt="" />
            </div>
            </div>
          </div>
        </div>
      </section>
      <Processing />
      <section className="mt-10">
        <div className="container">
          <div className="maket-card">
            <div className="row align-items-center">
              <div className="col-12  mt-md-0  order-md-1 order-2">
                <div className="p-lg-4 row justify-content-between h-100 gap-lg-0 gap-md-3 lg-gap-4 gap-2 py-3 ">
                  <div className="col-lg-6 ">
                    <h2 className="main-heading mb-0">84%</h2>
                    <p className="text-white sub-heading  mb-0">
                      of our customers sold above market price*
                    </p>
                  </div>
                  <div className="col-lg-6 set-btn-align-sell text-end">
                  <button class="gray-btn border-0 btnyellow"><span class=" ">Sell your Car</span></button>
                    <div className="d-flex gap-2 align-items-center justify-content-lg-end mt-15">
                      <p className="mb-0 opacity-75 color-light text-12 line-14 d-inline font-500">
                        This statistic represents the percentage of cars sold on
                        <br className="d-sm-block d-none" />
                        our platform that fetched a price higher than the market
                        value.
                      </p>
                      <div className="d-inline">
                        <InstantIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12  order-md-2 order-1">
                <img
                  src="/assets/img/home/customerbg.webp"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row mt-100 align-items-center">
            <div className="col-xl-4 col-12">
              <h6 className="sub-heading text-ld-start text-center mb-xl-0 weight-600">Recommended by</h6>
            </div>
            <div className="col-xl-8 col-12 d-flex flex-md-nowrap flex-wrap align-items-center gap_y justify-content-xl-end justify-content-center">
              <img
                src="/assets/img/home/bestprizing/price1.png"
                alt="logo"
                className="img-fluid"
              />
              <img
                src="/assets/img/home/bestprizing/price2.png"
                alt="logo"
                className="img-fluid"
              />
              {/* <img
                src="/assets/img/home/bestprizing/price3.png"
                alt="logo"
                className="img-fluid"
              /> */}
              <img
                src="/assets/img/home/bestprizing/price4.png"
                alt="logo"
                className="img-fluid"
              />
              <img
                src="/assets/img/home/bestprizing/price5.png"
                alt="logo"
                className="img-fluid"
                />
            </div>
          </div>
   
        </div>

      </section>
      <section>
      <div className='animation_section'>
            <div className='container-fluid gx-0'>
                <div className="nifi-animation">
                    <span>
                        <img src="/assets/img/animation.svg" alt="" />
                    </span>
                </div>
            </div>
        </div>
      </section>
      <HomeFaq />
      {/* <TopDeals /> */}

      {/* <section className="padding-block-80 mt-80 yellow-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="title_car">
                <span className="color-light"> Join </span>{" "}
                <span className="color-dark">500,000+</span>
                <span className="color-light">
                  {" "}
                  people <br /> who’ve sold the CarMax way{" "}
                </span>
              </h1>
            </div>
            <div className="col-md-6">
              <p className="text-md-end car_pera">
                “Compared to competitors, CarMax{" "}
                <br className="d-md-block d-none" />
                stood out as the most transparent and{" "}
                <br className="d-md-block d-none" />
                offered us a superior price.”
              </p>
            </div>
          </div>
        </div>
        <div className="mt-80">
          <div className="container-fluid">
            <Swiper
              spaceBetween={15}
              slidesPerView={4}
              freeMode={true}
              modules={[Autoplay, Navigation]}
              dir="rtl"
              loop={true}
              autoplay={{
                delay: 0,
              }}
              speed={3000}
              breakpoints={{
                320: {
                  slidesPerView: 1.2,
                  spaceBetween: 8,
                },

                400: {
                  slidesPerView: 1.4,
                  spaceBetween: 8,
                },

                576: {
                  slidesPerView: 2.1,
                  spaceBetween: 10,
                },

                768: {
                  slidesPerView: 2.4,
                  spaceBetween: 10,
                },

                820: {
                  slidesPerView: 2.9,
                  spaceBetween: 10,
                },

                992: {
                  slidesPerView: 3.1,
                  spaceBetween: 10,
                },

                1200: {
                  slidesPerView: 3.5,
                  spaceBetween: 15,
                },

                1400: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                },
              }}
            >
              <SwiperSlide>
                <CarMax />
              </SwiperSlide>
              <SwiperSlide>
                <CarMax />
              </SwiperSlide>
              <SwiperSlide>
                <CarMax />
              </SwiperSlide>
              <SwiperSlide>
                <CarMax />
              </SwiperSlide>
              <SwiperSlide>
                <CarMax />
              </SwiperSlide>
              <SwiperSlide>
                <CarMax />
              </SwiperSlide>
              <SwiperSlide>
                <CarMax />
              </SwiperSlide>
              <SwiperSlide>
                <CarMax />
              </SwiperSlide>
              <SwiperSlide>
                <CarMax />
              </SwiperSlide>
              <SwiperSlide>
                <CarMax />
              </SwiperSlide>
              <SwiperSlide>
                <CarMax />
              </SwiperSlide>
              <SwiperSlide>
                <CarMax />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section> */}
    </>
  );
}
