import React from 'react'
import { RightArrow, SliderSher } from './svg'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';


export const SliderCard = () => {
    return (
        <>
            <div className='waiting-card-for set-fix-w yellow-card perfect-hero-card p-20 w-100 slider-hover-card position-relative'>
                <div className="d-flex justify-content-between">
                    <span>29/03/2024</span>
                    <SliderSher />
                </div>
                <div className="mt-3">
                    <h4 className='small-heading '>Does the warranty impact the value of your car?</h4>
                </div>
                <p className='medium-heading color-dark opacity-75 mb-0 slider_space'>
                    our investments, especially when they’re something as sizable as a new or used car. Warranties can offer
                </p>
                <div className='hover-img'>
                    <img src="./assets/img/blog/slider/slider1.webp" alt="img" className='img-fluid' />
                </div>
            </div>
        </>
    )
}
export default function CarGuide() {
    return (
        <div className='card-car-guide'>
            <div className='p-50 pb-0'>
                <div className='d-flex flex-wrap justify-content-between align-items-center mb-lg-0 mb-md-3'>
                    <h2 className='big-middel-heading color-light order-md-1 order-2'>
                        <span className='color-yellow'>Latest</span> Car Guides
                    </h2>
                    <button className='border-lightrounded-btn order-md-2 order-1 mb-md-0 mb-3'>
                        Read All &nbsp; <RightArrow />
                    </button>
                </div>
                <p className='mb-0 color-light-gray opicity-75'>
                    Everything you need to know about selling, valuing, running and maintaining your car
                </p>
            </div>

            <div className='mt-20 flow-hide-set'>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={5}
                    centeredSlides={false}

                    breakpoints={{ 

                        320: {
                            slidesPerView: 1.19,
                            spaceBetween: 10,


                        },

                        380: {
                            slidesPerView: 1.25,
                            spaceBetween: 10,


                        },

                        420: {
                            slidesPerView: 1.3,
                            spaceBetween: 10,


                        },

                        470: {
                            slidesPerView: 1.5,
                            spaceBetween: 10,


                        },

                        515: {
                            slidesPerView: 1.7,
                            spaceBetween: 10,


                        },
                        
                        540: {
                            slidesPerView: 1.8,
                            spaceBetween: 10,

                        },
                     

                        768: {
                            slidesPerView: 2.5,
                            spaceBetween: 10,
                        },

                        992: {
                            slidesPerView: 3.3,
                            spaceBetween: 10,
                        },

                        1200: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                        },
                        
                        1400: {
                            slidesPerView: 4.6,
                            spaceBetween: 10,
                        },
                    }}
                >
                    <SwiperSlide>
                        <SliderCard />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard />

                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard />
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className='mt-50 d-inline-block'>
            </div>
        </div>
    )
}
