import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ROOT_URL } from "../utils/rootUrl";
import { MessageBox } from "./MessageBox";
import postalCodeChecker from "postal-codes-js";

export default function SectionHero() {
  
  const [activeTab, setActiveTab] = useState('VIN')
  const [inputs, setInputs] = useState({
    vin_number: "",
    lic_number: "",
    zip_code: "",
    state_code: ""
  });
  const [validations, setValidations] = useState({
    vin_number: false,
    lic_number: false,
    zip_code: false,
    state_code: false
  });
  const [loading, setLoading] = useState(false);
  const [stateCode, setStateCode] = useState([])
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "zip_code" && !/^[0-9]+$|^$/.test(value)) return;
    if (name === "state_code" && !/^[A-Z]+$|^$/.test(value)) return;
    setInputs((prev) => ({ ...prev, [name]: value }));
    if (validations[name])
      setValidations((prev) => ({ ...prev, [name]: false }));
  };

  const validatePincode = (code) => {
    return postalCodeChecker.validate("USA", code) === true;
  };

  const checkValidations = () => {
    if (loading) return;
    if (activeTab === 'VIN' && !inputs.vin_number) {
      setValidations((prev) => ({ ...prev, vin_number: true }));
      return;
    }
    if (activeTab === 'LIC' && !inputs.lic_number) {
      setValidations((prev) => ({ ...prev, lic_number: true }));
      return;
    }
    if (!inputs.zip_code || !validatePincode(inputs.zip_code)) {
      setValidations((prev) => ({ ...prev, zip_code: true }));
      return;
    }
    if (!inputs.state_code) {
      setValidations((prev) => ({ ...prev, state_code: true }));
      return;
    }
    getCarDetails();
  };

  const getCarDetails = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${ROOT_URL}/car_vin_detail`, {
        vinNo: activeTab === 'VIN' ? inputs.vin_number : inputs.lic_number,
        zip_code: inputs.zip_code,
        state_code: inputs.state_code,
        type: activeTab === 'LIC' ? 'plateDecoder' : ''
      });
      if (res.data?.status === "success") {
        localStorage.removeItem("offer-details");
        localStorage.removeItem("current-step");
        navigate("/get-offer", {
          state: {
            ...inputs,
            ...(res.data.data?.carVindetails || {}),
            fname: localStorage.getItem("fname") || "",
            lname: localStorage.getItem("lname") || ""
          },
        });
      } else {
        MessageBox("error", res.data?.msg);
      }
    } catch (err) {
      MessageBox("error", err?.response?.data?.msg || "Something went wrong");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    axios.get(`${ROOT_URL}/stateCode`).then(res => {
      if (res.status == 200) {
        setStateCode(res.data.data)
      }
    }).catch(err => {
      MessageBox('error', err?.res?.data?.msg || 'Something went wrong')
      console.log(err, 'errorororororo')
    })
  }, [])

  return (
    <div className="hero-section">
      <div className="container">
        <Header className={"light-nav"} />
        <div className="row lg-mt-95 md-mt-60">
          <div className="col-12 hero_title">
            <h1 className="main-heading">
              Sell Your Car For <br className="d-sm-block d-none" /> What it’s Really Worth With {" "}
              <br className="d-sm-block d-none" />
             <span className="color-yellow">Instant Cash Price</span>
            </h1>
            <p className="mb-0 color-light-gray opacity-75">
              84% of customers get more money*. Get a free, instant Price.
            </p>
          </div>
          <div className="col-lg-6 col-md-10">
            <div className="">
              <ul
                className="nav nav-pills perfect-tab yellow_tab"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link vin_btn ${activeTab === 'VIN' ? 'active' : ''}`}
                    onClick={() => setActiveTab('VIN')}
                  >
                    VIN
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link vin_btn ${activeTab === 'LIC' ? 'active' : ''}`}
                    onClick={() => setActiveTab('LIC')}
                  >
                    Licence Plate
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex={0}
                >
                  <div className="row m-0 mb-3">
                    {
                      activeTab === 'VIN' ?
                        <div className="col-sm-12 mb_10 p-0">                         
                            <input
                              type="text"
                              className="form-control form_code"
                              placeholder="ENTER VIN NO."
                              name="vin_number"
                              value={inputs.vin_number}
                              onChange={handleInputChange}
                            />                          
                          {validations.vin_number && (
                            <div className="err_msg">Please enter VIN number</div>
                          )}
                        </div>
                        :
                        <div className="col-sm-12 mb_10 p-0">
                          {/* <div className="min-border-set"> */}
                            <input
                              type="text"
                              className="form-control form_code"
                              placeholder="ENTER LICENCE NO."
                              name="lic_number"
                              value={inputs.lic_number}
                              onChange={handleInputChange}
                            />
                          {/* </div> */}
                          {validations.lic_number && (
                            <div className="err_msg">Please enter licence number</div>
                          )}
                        </div>
                    }
                    <div className="col-sm-6 px-set-align ps-0 py-set-align">                     
                        <input
                          type="text"
                          className="form-control form_code"
                          placeholder="ZIP CODE"
                          name="zip_code"
                          value={inputs.zip_code}
                          onChange={handleInputChange}
                        />                      
                      {validations.zip_code && (
                        <div className="err_msg">
                          {!inputs.zip_code
                            ? "Please enter ZIP code"
                            : "Please enter valid USA ZIP code"}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 px-set-align pe-0 py-set-align mb-0">                     
                        <select
                          className={`form-select select_box ${!inputs.state_code && 'text_color'}`}
                          name="state_code"
                          maxLength={2}
                          value={inputs.state_code}
                          onChange={(e) => handleInputChange({ target: { name: 'state_code', value: e.target.value.toUpperCase() } })}
                        >
                          <option className="state_code" value=''>STATE CODE</option>

                          {stateCode.map((i, index) =>
                            <option key={index} value={i.code}>{i.code} | {i.name}</option>
                          )}

                        </select>                     
                      {validations.state_code && (
                        <div className="err_msg">
                          {!inputs.state_code
                            ? "Please select state code"
                            : "Please select valid state code"}
                        </div>
                      )}
                    </div>


                    {/* <div className="col-sm-6 px-set-align">
                      <div className="min-border-set">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="STATE CODE"
                          name="state_code"
                          maxLength={2}
                          value={inputs.state_code}
                          onChange={(e) => handleInputChange({ target: { name: 'state_code', value: e.target.value.toUpperCase() } })}
                        />
                      </div>
                      {validations.state_code && (
                        <div className="err_msg">
                          {!inputs.state_code
                            ? "Please enter state code"
                            : "Please enter valid state code"}
                        </div>
                      )}
                    </div> */}
                  </div>
                  <div className="d-flex gap-3 pt-1 flex-sm-nowrap flex-wrap align-items-center">
                    <button
                      className="btn-bor-remove border-0 instant-brn"
                      onClick={checkValidations}
                    >
                      <span>
                        {loading ? "Loading Offer..." : "Get an Instant Price"}
                      </span>
                    </button>
                    <div>
                      <p className="mb-1 heding-normal color-light">Is this vehicle a lease?</p>
                      <span className="color-yellow pointer">
                        Learn More &#62;
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabIndex={0}
                >
                  no
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
