import './App.css'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Home from './pages/Home'
import GetOffer from './pages/GetOffer'
import Login from './auth/Login'
import Register from './auth/Register'
import Welcome from './pages/Welcome'
import Verify from './auth/Verify'
import ResetPassword from './auth/ResetPassword'
import ForgotPassword from './auth/ForgotPassword'
import Blog from './pages/Blog'
import TrueStories from './pages/TrueStories'
import CookiePolices from './pages/CookiePolices'
import TermsOfUse from './pages/TermsOfUse'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Guide from './pages/Guide'
import TrueStoriesDetail from './pages/TrueStoriesDetail'
import BlogDetail from './pages/BlogDetail'
import ContactUs from './pages/ContactUs'
import Faq from './pages/Faq'
import About from './pages/About'
import ValueTracker from './pages/ValueTracker'
import NotFound from './pages/NotFound'
import Dashboard from './pages/Dashboard'

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/value-tracker",
      element: <ValueTracker />,
    },
    {
      path: "/get-offer",
      element: <GetOffer />,
    },
    {
      path: "/blog",
      element: <Blog />,
    },
    {
      path: "/blog-detail",
      element: <BlogDetail />,
    },
    {
      path: "/true-story",
      element: <TrueStories />,
    },
    {
      path: "/true-story-detail",
      element: <TrueStoriesDetail />,
    },
    {
      path: "/cookie-polices",
      element: <CookiePolices />,
    },
    {
      path: "/terms-of-use",
      element: <TermsOfUse />,
    },
    {
      path: "/contact-us",
      element: <ContactUs />,
    },
    {
      path: "/faq",
      element: <Faq />,
    },
    {
      path: "/guide",
      element: <Guide />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: '/dashboard',
      element: <Dashboard />
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/welcome",
      element: <Welcome />,
    },
    {
      path: "/verify",
      element: <Verify />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "*",
      element: <NotFound />,
    }
  ])

  return (
    <RouterProvider router={router} />
  )
}

export default App
