import React from 'react'
import { YellowLine } from '../svg'

const ext_colors = [
  {
    name: 'Bianco',
    hex: '#E3E3E2'
  },
  {
    name: 'Blu Passione',
    hex: '#1B2339'
  },
  {
    name: 'Bronzo Siena',
    hex: '#4D4841'
  },
  {
    name: 'Champagne',
    hex: '#9A968D'
  },
  {
    name: 'Grigio metallo',
    hex: '#B9B9B9'
  },
  {
    name: 'Nero',
    hex: '#000000'
  },
  {
    name: 'Nero ribelle',
    hex: '#272B2C'
  },
  {
    name: 'Rosso folgore',
    hex: '#2E2528'
  }
]

const int_colors = [
  {
    name: 'Marrone',
    hex: '#513D3C'
  },
  {
    name: 'Sabbia',
    hex: '#E8D8BE'
  },
  {
    name: 'Tortora',
    hex: '#838288'
  }
]

export default function StepColorExterior({ inputs, validations, setValidations, handleInputChange, setCurrentStep }) {

  const checkValidations = () => {
    if (!inputs.ext_color) {
      setValidations(prev => ({ ...prev, ext_color: true }))
      return
    }
    if (!inputs.int_color) {
      setValidations(prev => ({ ...prev, int_color: true }))
      return
    }
    setCurrentStep(prev => (prev + 1))
  }

  return (
    <div>
      <h2 className='sub-heading mt-40 mb-0'>
        We found your car! <br className='d-lg-block' />
        Let’s confirm a few details.
      </h2>
      <div className='car-functionality mt-40'>
        <YellowLine />
        <p className='mb-0 ms-1 text-uppercase d-inline'>
          What <span className='color-purplr'>
            color </span> is your vehicle's
          <span className='color-purplr'> exterior?
          </span>
        </p>
      </div>
      <div className='row row-cols-2 row-cols-xl-5 row-cols-lg-3 row-cols-md-4 g-3 g-lg-3 mt-20'>
        {
          inputs?.color &&
          <div className='col'>
            <div
              className={`color-palettes text-center${inputs.ext_color === inputs.color ? ' active' : ''}`}
              onClick={() => handleInputChange({ target: { name: 'ext_color', value: inputs.color } })}
            >
              <div className='color-box mb-2' style={{ background: inputs.color }}></div>
              {inputs.color}
            </div>
          </div>
        }
        {
          ext_colors.map((i, index) =>
            <div className='col' key={index}>
              <div
                className={`color-palettes text-center${inputs.ext_color === i.name ? ' active' : ''}`}
                onClick={() => handleInputChange({ target: { name: 'ext_color', value: i.name } })}
              >
                <div className='color-box mb-2' style={{ background: i.hex }}></div>
                {i.name}
              </div>
            </div>
          )
        }
      </div>
      {validations.ext_color && <div className='err_msg'>Please select exterior</div>}
      <div className='col-12 mt-40'>
        <div className='car-functionality'>
          <YellowLine />
          <p className='mb-0 ms-1 text-uppercase d-inline'>
            What <span className='color-purplr'> color </span>is your vehicle's <span className='color-purplr'> interior?</span>
          </p>
        </div>
        <div className='row row-cols-2 row-cols-xl-5 row-cols-lg-3 row-cols-md-4 g-3 g-lg-3 mt-20'>
          {
            int_colors.map((i, index) =>
              <div className='col' key={index}>
                <div
                  className={`color-palettes text-center${inputs.int_color === i.name ? ' active' : ''}`}
                  onClick={() => handleInputChange({ target: { name: 'int_color', value: i.name } })}
                >
                  <div className='color-box mb-2' style={{ background: i.hex }}></div>
                  {i.name}
                </div>
              </div>
            )
          }
        </div>
        {validations.int_color && <div className='err_msg'>Please select interior</div>}
      </div>
      <div className='mt-40'>
        <button className='gray-btn border-0 btnyellow' onClick={checkValidations}>
          <span className='wx-170'>Next</span>
        </button>
      </div>
    </div>
  )
}
